/* prefixed by https://autoprefixer.github.io (PostCSS: v7.0.26, autoprefixer: v9.7.3) */

@font-face {
	font-family: "Noto Sans SC";
	src: local("Noto Sans SC"),
		url(./assets/fonts/noto-sans-sc-v11-latin-regular.woff2) format("woff2"),
		url(./assets/fonts/noto-sans-sc-v11-latin-regular.woff) format("woff"),
		url(./assets/fonts/noto-sans-sc-v11-latin-regular.svg) format("svg"),
		url(./assets/fonts/noto-sans-sc-v11-latin-regular.eot) format("eot");
}

/* lato-regular - latin */
@font-face {
	font-family: "Lato";
	font-style: normal;
	font-weight: 400;
	src: url("./assets/fonts/lato-v16-latin-regular.eot"); /* IE9 Compat Modes */
	src: local("Lato Regular"), local("Lato-Regular"),
		url("./assets/fonts/lato-v16-latin-regular.eot?#iefix")
			format("embedded-opentype"),
		/* IE6-IE8 */ url("./assets/fonts/lato-v16-latin-regular.woff2")
			format("woff2"),
		/* Super Modern Browsers */
			url("./assets/fonts/lato-v16-latin-regular.woff") format("woff"),
		/* Modern Browsers */ url("./assets/fonts/lato-v16-latin-regular.ttf")
			format("truetype"),
		/* Safari, Android, iOS */
			url("./assets/fonts/lato-v16-latin-regular.svg#Lato") format("svg"); /* Legacy iOS */
}
/* lato-italic - latin */
@font-face {
	font-family: "Lato";
	font-style: italic;
	font-weight: 400;
	src: url("./assets/fonts/lato-v16-latin-italic.eot"); /* IE9 Compat Modes */
	src: local("Lato Italic"), local("Lato-Italic"),
		url("./assets/fonts/lato-v16-latin-italic.eot?#iefix")
			format("embedded-opentype"),
		/* IE6-IE8 */ url("./assets/fonts/lato-v16-latin-italic.woff2")
			format("woff2"),
		/* Super Modern Browsers */
			url("./assets/fonts/lato-v16-latin-italic.woff") format("woff"),
		/* Modern Browsers */ url("./assets/fonts/lato-v16-latin-italic.ttf")
			format("truetype"),
		/* Safari, Android, iOS */
			url("./assets/fonts/lato-v16-latin-italic.svg#Lato") format("svg"); /* Legacy iOS */
}
/* lato-700 - latin */
@font-face {
	font-family: "Lato";
	font-style: normal;
	font-weight: 700;
	src: url("./assets/fonts/lato-v16-latin-700.eot"); /* IE9 Compat Modes */
	src: local("Lato Bold"), local("Lato-Bold"),
		url("./assets/fonts/lato-v16-latin-700.eot?#iefix")
			format("embedded-opentype"),
		/* IE6-IE8 */ url("./assets/fonts/lato-v16-latin-700.woff2")
			format("woff2"),
		/* Super Modern Browsers */
			url("./assets/fonts/lato-v16-latin-700.woff") format("woff"),
		/* Modern Browsers */ url("./assets/fonts/lato-v16-latin-700.ttf")
			format("truetype"),
		/* Safari, Android, iOS */
			url("./assets/fonts/lato-v16-latin-700.svg#Lato") format("svg"); /* Legacy iOS */
}
/* lato-700italic - latin */
@font-face {
	font-family: "Lato";
	font-style: italic;
	font-weight: 700;
	src: url("./assets/fonts/lato-v16-latin-700italic.eot"); /* IE9 Compat Modes */
	src: local("Lato Bold Italic"), local("Lato-BoldItalic"),
		url("./assets/fonts/lato-v16-latin-700italic.eot?#iefix")
			format("embedded-opentype"),
		/* IE6-IE8 */ url("./assets/fonts/lato-v16-latin-700italic.woff2")
			format("woff2"),
		/* Super Modern Browsers */
			url("./assets/fonts/lato-v16-latin-700italic.woff") format("woff"),
		/* Modern Browsers */ url("./assets/fonts/lato-v16-latin-700italic.ttf")
			format("truetype"),
		/* Safari, Android, iOS */
			url("./assets/fonts/lato-v16-latin-700italic.svg#Lato")
			format("svg"); /* Legacy iOS */
}

html,
body {
	margin: 0;

	/*
	 * atlassian fonts
	*/
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
		"Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
		"Helvetica Neue", sans-serif !important;
	font-weight: 400 !important;
	font-size: 14px !important;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

/* 
 *for the grid 
*/
.row {
	padding: 2em 0 !important;
}

.ui.statistic:first-child {
	margin: 0 !important;
}

/*
* minor customization to elements
*/
.ui.xs.statistic > .value {
	font-size: 1rem !important;
}
.ui.xs.statistic > .label {
	font-size: 0.8rem !important;
	color: unset !important;
	font-weight: normal !important;
	color: rgba(0, 0, 0, 0.4) !important;
}
